<template>
  <div class="content-body">
    <!-- modal -->
    <b-modal
      id="file_upload_modal"
      ref="file_upload_modal"
      hide-footer
      title="Upload CSV File"
      size="lg"
    >
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleFileUpload">
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-file
                v-model="file"
                accept=".csv"
                placeholder="Choose a CSV file or drop it here..."
                drop-placeholder="Drop file here..."
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </b-col>

          <b-col class="d-flex justify-content-center mt-2">
            <b-button
              type="submit"
              variant="success"
              class="mr-1"
              :disabled="invalid"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              Upload
            </b-button>

            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click.prevent="handleDownloadButtonClick"
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              Download Sample File
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <div class="card">
      <div class="card-header">
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        >
          <div class="w-100 row justify-content-between align-items-center">
            <div
              class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
            >
              <h3 class="mb-50 font-weight-bold">Asset Register</h3>
              <p class="mb-0">Manage all your assets.</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <div
                class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
              >
                <b-button
                  @click="handleAddAssetClick()"
                  size="sm"
                  variant="success"
                >
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span>New Asset</span></b-button
                >
              </div>
            </div>
          </div>

          <div class="col mt-1 py-1 border rounded">
            <b-row class="d-flex justify-content-between mb-2">
              <h4 class="mx-2 font-weight-bold">
                Filters
                <feather-icon class="ml-55" size="20" icon="FilterIcon" />
              </h4>
              <b-button
                variant="outline-danger"
                class="mx-2"
                size="sm"
                @click="handleClearButtonClick"
                ><feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Clear Filters</span></b-button
              >
            </b-row>

            <div class="row d-flex justify-content-start">
              <b-form-group label="Asset Name" class="mx-2">
                <b-input-group size="md">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search.."
                  />
                </b-input-group>
              </b-form-group>

              <b-form-group class="mx-2" label="Owner" variant="primary">
                <vue-autosuggest
                  :suggestions="asset_owner_options"
                  :limit="5"
                  v-model="asset_owner_name"
                  id="autosuggest__input"
                  :input-props="ownerInputProps"
                  @input="getAssetOwnersBySearchPhrase"
                  @selected="onOwnerSelected"
                >
                  <template slot-scope="{ suggestion }">
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.firstname }}
                      {{ suggestion.item.lastname }}</span
                    >
                  </template>
                </vue-autosuggest>
              </b-form-group>

              <b-form-group class="mx-2" label="Tag">
                <vue-autosuggest
                  :suggestions="asset_tag_options"
                  :limit="5"
                  v-model="asset_tag_name"
                  id="autosuggest__input"
                  :input-props="tagInputProps"
                  @input="getAssetTagsBySearchPhraseAndSetOptions"
                  @selected="onTagSelected"
                  :get-suggestion-value="getTagSuggestionValue"
                >
                  <template slot-scope="{ suggestion }">
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.title }}
                    </span>
                  </template>
                </vue-autosuggest>
              </b-form-group>

              <b-form-group label="Category" class="mx-2">
                <validation-provider name="asset_type">
                  <b-form-select
                    v-model="asset_type"
                    :options="asset_type_options"
                  ></b-form-select>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Classification" class="mx-2">
                <validation-provider name="asset_class">
                  <b-form-select
                    v-model="asset_class"
                    :options="asset_class_options"
                  ></b-form-select>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Department" class="mx-2">
                <validation-provider name="department_type">
                  <b-form-select
                    v-model="department"
                    :options="departmentOptions"
                  ></b-form-select>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Risk Value" class="mx-2">
                <validation-provider name="Risk Value">
                  <b-form-select
                    :class="{
                      highlighted: highlightedFilter == 'risk_value_category',
                    }"
                    v-model="risk_value_category"
                    :options="valueOptions"
                  ></b-form-select>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="dark"
      >
        <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col">Owner</th>
              <th role="columnheader" scope="col">Asset Value</th>
              <th role="columnheader" scope="col">Location</th>
              <th role="columnheader" scope="col">Action</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="assets && assets.length > 0">
              <tr
                v-for="asset in assets"
                :key="asset._id"
                role="row"
                @click="handleAssetClick(asset._id)"
              >
                <!--  @click="handleAssetClick(asset._id)" -->
                <td
                  v-if="asset && asset != null"
                  aria-colindex="1"
                  role="cell"
                  style="max-width: 25vw"
                >
                  <div
                    class="w-100 d-flex flex-column align-items-start justify-content-center"
                  >
                    <div
                      class="w-100"
                      v-if="asset.asset_name && asset.asset_name != null"
                    >
                      <p class="mb-0">
                        {{ asset.asset_name }}
                      </p>
                    </div>
                    <div class="w-100 mt-25">
                      <template v-for="(tag, i) in asset.tags">
                        <b-badge
                          :key="tag._id"
                          variant="light-primary"
                          class="mr-25"
                          style="margin-bottom: 2px, width: 60px"
                          v-b-tooltip.hover.bottom.v-secondary
                          :title="tag.title"
                          v-if="i < 2"
                        >
                          <p
                            style="width: 60px; line-height: 13px"
                            class="text-truncate p-0 m-0"
                          >
                            {{ tag.title }}
                          </p>
                        </b-badge>
                      </template>
                      <p
                        v-if="asset.tags.length > 2"
                        class="mb-0 font-weight-bold"
                      >
                        & {{ asset.tags.length - 2 }} more..
                      </p>
                    </div>
                  </div>
                </td>

                <td aria-colindex="2" role="cell">
                  <p class="mb-0" v-if="asset.owner && asset.owner !== null">
                    {{ asset.owner.firstname }} {{ asset.owner.lastname }}
                  </p>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>
                <td aria-colindex="3" role="cell">
                  <b-badge
                    v-if="asset.asset_value && asset.asset_value !== null"
                    v-bind:variant="getVariant(parseInt(asset.asset_value))"
                    >{{
                      getScoreValueFromNumber(parseInt(asset.asset_value))
                    }}</b-badge
                  >
                </td>
                <td aria-colindex="5" role="cell">
                  <p
                    class="mb-0"
                    v-if="
                      asset.asset_location &&
                      asset.asset_location !== null &&
                      asset.asset_location[0] &&
                      asset.asset_location[0] != null
                    "
                  >
                    {{ asset.asset_location[0] }}
                  </p>
                </td>

                <td aria-colindex="6" role="cell">
                  <b-dropdown variant="outline-primary" size="sm" text="Select">
                    <b-dropdown-item
                      @click.stop="
                        $router.push({
                          name: 'asset-edit',
                          params: { id: asset._id },
                        })
                      "
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item
                      @click.stop="showConfirmDeleteMsgBox(asset._id)"
                      >Delete</b-dropdown-item
                    >
                  </b-dropdown>
                </td>
                <!-- <td role="cell">{{ asset.asset_classification }}</td> -->
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Assets Not Found">
                <template #content>
                  <p class="font-weight-bold text-center">
                    It seems like there are no Assets at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
      </b-overlay>
      <div class="card-body">
        <div class="row">
          <div class="d-flex align-items-center justify-content-center col-12">
            <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.rows"
              aria-controls="my-table"
              class="mb-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  BCard,
  BPagination,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormSelect,
  BForm,
  BModal,
  VBModal,
  BFormFile,
  BCol,
  BRow,
  BBadge,
  BOverlay,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TagMixins from "@/mixins/TagMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import CustomRisk from "../RiskRegister/CustomRisk.vue";
export default {
  components: {
    BCard,
    FeatherIcon,
    BPagination,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BForm,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormFile,
    BCol,
    BRow,
    BBadge,
    BOverlay,
    BSpinner,
    EmptyTableSection,
    SidebarTemplate,
    CustomRisk,
  },
  name: "AssetPage",
  mixins: [TagMixins],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    highlight: {
      bind(el, binding) {
        if (binding.value) {
          el.classList.add("highlighted"); // Apply the highlight when the directive is bound
        }
      },
    },
  },
  data() {
    return {
      highlightedFilter: null,
      file: null,
      timeout: null,
      debounceMilliseconds: 250,
      selectedAction: null,
      actionOptions: [],
      showOverlay: false,
      asset_owner: null,
      asset_owner_name: "",
      asset_owner_options: [],
      ownerInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      asset_tag: null,
      asset_tag_name: "",
      asset_tag_options: [],
      selectedTag: null,
      tagInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      asset_type: null,
      asset_type_options: [{ value: null, text: "--Select Asset Category--" }],
      asset_type_name: "",

      asset_class: null,
      asset_class_name: "",
      asset_class_options: [
        { value: null, text: "--Select Asset Classification--" },
      ],

      department: null,
      departmentOptions: [{ value: null, text: "--Select Department--" }],
      departmentName: "",
      classInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },

      risk_value_category: null,
      valueOptions: [
        { value: null, text: "--Select Value--" },
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
        { value: "critical", text: "Critical" },
      ],

      filter: "",
      assets: [],
      currentPage: 1,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
    };
  },

  watch: {
    currentPage(newPage) {
      this.getAssetsByPageNumber(newPage);
    },
    filter(name) {
      this.getAssetsByPageNumber(this.currentPage);
    },
    asset_owner(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    asset_class(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    department(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    risk_value_category() {
      this.getAssetsByPageNumber(this.currentPage);
    },
    selectedTag(val) {
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
    asset_type(val) {
      console.log(val);
      if (val !== null) {
        this.getAssetsByPageNumber(this.currentPage);
      }
    },
  },
  mounted() {
    this.load();
    this.getAssetTypes();
    this.getAssetClassifications();
    this.getAllDepartments();
  },

  methods: {
    load() {
      const queryParams = this.$route.query;

      if ("risk_value_category" in queryParams) {
        this.risk_value_category = queryParams["risk_value_category"];
        this.setAnimation("risk_value_category");
      } else {
        this.getAssetsByPageNumber(this.currentPage);
      }

      this.$router.replace({ query: null });
    },

    setAnimation(filter) {
      this.highlightedFilter = filter;
      setTimeout(() => {
        this.highlightedFilter = null;
      }, 2700);
    },

    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Very High";
        default:
          return "Very Low";
      }
    },
    getVariant(status) {
      switch (status) {
        case 1:
          return "info";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "danger";
        case 5:
          return "primary";
        default:
          return "info";
      }
    },

    handleDownloadButtonClick() {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_BASEURL}/assets/import/csv/sample-download`,
      };
      this.$http(options)
        .then((res) => {
          var blob = new Blob([res.data]);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, "sample.csv");
          } else {
            var a = window.document.createElement("a");

            a.href = window.URL.createObjectURL(blob, {
              type: "text/plain",
            });
            a.download = "sample.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((err) => console.log(err));
    },
    handleAddAssetClick() {
      router.push({ name: "asset-add" });
    },
    getAssets() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets`,
      };
      this.$http(options)
        .then((res) => {
          console.log();
          this.assets = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getAssetsByPageNumber(num) {
      if ((num !== null) | (num !== 0)) {
        this.showOverlay = true;
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets`,
          params: {
            page: num,
            search: this.filter !== "" ? this.filter : null,
            asset_owner_id: this.asset_owner ? this.asset_owner.user_id : null,
            asset_classification_id: this.asset_class ? this.asset_class : null,
            asset_category_id: this.asset_type ? this.asset_type : null,
            department_id: this.department ? this.department : null,
            tag: this.selectedTag ? this.selectedTag.title : null,
            risk_value_category: this.risk_value_category
              ? this.risk_value_category
              : null,
          },
        };
        this.$http(options)
          .then((res) => {
            this.assets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.pagination.lastPage = res.data.data.last_page;
            this.pagination.perPage = res.data.data.per_page;
            this.pagination.rows = res.data.data.last_page * 10;
            this.showOverlay = false;
          })
          .catch((err) => {
            console.log("err", err);
            this.showOverlay = false;
          });
      }
    },
    getAssetsByFilter(search, page) {
      if ((search !== null) & (page !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets`,
          params: {
            page: page,
            search: search,
          },
        };
        this.$http(options)
          .then((res) => {
            // this.$set(this.assets, res.data.data.data);
            console.log("name", res.data.data.data);
            this.assets = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.pagination.lastPage = res.data.data.last_page;
            this.pagination.perPage = res.data.data.per_page;
            this.pagination.rows = res.data.data.last_page * 10;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    handleAssetClick(id) {
      router.push({ name: "asset-details", params: { id: id } });
    },
    deleteAssetWithId(id) {
      const options = {
        method: "DELETE",
        url: `${process.env.VUE_APP_BASEURL}/assets/` + id,
      };
      this.$http(options)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Asset Deleted Successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          if (this.filter !== "") {
            console.log("Asset Length", this.assets.length);
            if (this.assets.length == 1 && this.currentPage > 1) {
              this.getAssetsByFilter(this.filter, this.currentPage - 1);
            } else {
              this.getAssetsByFilter(this.filter, this.currentPage);
            }
          } else {
            if (this.assets.length == 1 && this.currentPage > 1) {
              this.getAssetsByPageNumber(this.currentPage - 1);
            } else {
              this.getAssetsByPageNumber(this.currentPage);
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "The Asset has not been Deleted",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showConfirmDeleteMsgBox(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this Asset.", {
          title: "Delete Asset",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteAssetWithId(id);
          }
        });
    },
    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },
    onTagSelected(val) {
      this.selectedTag = val.item;
    },
    getAssetTagsBySearchPhraseAndSetOptions(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.asset_tag_options = [];
              let tags = res.data.data.data;
              this.asset_tag_options.push({ name: "tags", data: tags });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.asset_tag_options = [];
        this.asset_tag = null;
        this.asset_tag_name = "";
      }
    },
    getAssetOwnersBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: { search: searchPhrase, limit: 5 },
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.asset_owner_options = [];
              let owners = res.data.data.data;
              console.log("owners", owners);
              this.asset_owner_options.push({ name: "owners", data: owners });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.asset_owner_options = [];
        this.asset_owner = null;
        this.asset_owner_name = "";
      }
    },
    onOwnerSelected(owner) {
      console.log("Owner", owner);
      this.asset_owner = owner.item;
      this.asset_owner_name = `${owner.item.firstname} ${owner.item.lastname}`;
    },
    // getAssetTypes() {
    //   const options = {
    //     method: "GET",
    //     headers: { "content-type": "application/x-www-form-urlencoded" },
    //     url: `${process.env.VUE_APP_ONECSFURL}/ast_category/search`,
    //   };
    //   this.$http(options)
    //     .then((res) => {
    //       let assettypes = res.data.data;
    //       for (let i = 0; i < assettypes.length; i++) {
    //         this.asset_type_options.push({
    //           value: assettypes[i]._id,
    //           text: assettypes[i].name,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // },

    getAssetTypes() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/asset-categories/master`,
        params: {
          dont_paginate: true,
        },
      };
      this.$http(options)
        .then((res) => {
          // console.log(res)
          let assettypes = res.data.data;
          for (let i = 0; i < assettypes.length; i++) {
            this.asset_type_options.push({
              value: assettypes[i]._id,
              text: assettypes[i].name,
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    getAssetClassifications() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/asset-classifications`,
        params: { dont_paginate: true },
      };
      this.$http(options)
        .then((res) => {
          let classifications = res.data.data;
          for (let i = 0; i < classifications.length; i++) {
            this.asset_class_options.push({
              value: classifications[i]._id,
              text: classifications[i].title,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleClearButtonClick() {
      this.asset_class = null;
      this.asset_owner = null;
      this.asset_owner_name = "";
      this.asset_type = null;
      this.filter = "";
      this.department = null;
      this.selectedTag = null;
      this.asset_tag = null;
      this.asset_tag_name = "";
      this.risk_value_category = null;
      this.getAssetsByPageNumber(this.currentPage);
    },
    getAllDepartments() {
      this.departmentOptions = [{ value: null, text: "--Select Department--" }];
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments`,
        params: { dont_paginate: true },
      };
      this.$http(options).then((res) => {
        let newDepartments = res.data.data;
        for (var i = 0; i < newDepartments.length; i++) {
          this.departmentOptions.push({
            value: newDepartments[i]._id,
            text: newDepartments[i].title,
          });
        }
      });
    },
    showModal() {
      this.$refs["file_upload_modal"].show();
    },
    hideModal() {
      this.$refs["file_upload_modal"].hide();
    },
    handleFileUpload() {
      const bodyFormData = new FormData();
      bodyFormData.append("assets_list_csv", this.file);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets/import/csv`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "File has been successfully Uploaded",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.hideModal();
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.hideModal();
        });
    },
  },
};
</script>

<style></style>
<style scoped>
.highlighted {
  animation: breathingEffect 1s infinite ease-in-out; /* Apply breathing animation for 7 seconds */
}

@keyframes breathingEffect {
  0% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
  50% {
    border: 1px solid transparent;
    box-shadow: none;
  }
  100% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
}
</style>
